import { useEffect, useState } from "react";
import Video from "../components/Video";
import VideoHeader from "../components/Video/VideoHeader";
import config from "../config";
import { useRouter } from "next/router";
import VideoNotAvailable from "./VideoNotAvailable";
import VideoIsBeingProcessed from "./VideoIsBeingProcessed";
import pRetry, { AbortError } from "p-retry";

import qs from "qs";
import Skeleton from "react-loading-skeleton";
import { UAParser } from "ua-parser-js";
import "react-loading-skeleton/dist/skeleton.css";

import transformVideoMetadata from "../utils/transformVideoMetadata";
/*
function useWindowSize() {
  const [size, setSize] = useState([1920, 1080]);
  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
} */

function VideoPage(props) {
  //const [width, height] = useWindowSize();
  //const [status, setStatus] = useState(props.status);
  const status = props.status;
  //const [videoObj, setVideoObj] = useState(props.videoObj);
  const videoObj = props.videoObj;
  //const [projectid, setProjectid] = useState(null);
  const router = useRouter();
  let hideScript = false;
  let showHeader = true;
  let showFooter = true;

  let embedVideo = qs.parse(router.asPath.substring(router.asPath.indexOf("?")), {
    ignoreQueryPrefix: true,
  }).embedVideo;
  if (embedVideo && embedVideo === "true") {
    embedVideo = true;
    hideScript = true;
    showHeader = false;
    showFooter = false;
  } else embedVideo = false;

  let width = 1920;
  let height = (width / 16) * 9;
  if (props?.deviceType === "mobile" || props?.deviceType === "tablet") width = 640;

  // calculate and update video height and width
  let videoWidth = width - 60;
  if (embedVideo) {
    videoWidth = width - 1;
  }
  if (width > 767 && !embedVideo) videoWidth = Math.min(width - 150, width * 0.55);

  let adjustedHeight = videoWidth / props.properties.widthRatio;

  let textStyle = {
    height: adjustedHeight + 20,
    maxWidth: "120vh",
    minWidth: width - videoWidth - 150,
  };

  if (width - videoWidth - 120 > 300) textStyle.maxWidth = width * 0.35 - 50; //width - videoWidth - 150;

  /*
  useEffect(() => {
    if (props.projectid) setProjectid(props.projectid);
  }, [props.projectid]);

  useEffect(async () => {
    if (projectid && props.status === "ready" && props.videoObj) {
      setVideoObj(props.videoObj);
      setStatus("ready");
    }
    if (props.status != "ready" && projectid) {
      const getResponse = async () => {
        const response = await fetch(`${config.socketIo}/player/loadProject`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify({ projectid }),
        });
        const json = await response.json();

        return json;
      };
      const result = await pRetry(getResponse, { retries: 10, factor: 1.5, minTimeout: 1000 });
      //props.videoObj = result.video;
      //props.status = result.status;
      if (result.status === "ready") {
        setVideoObj(result.video);
      }
      setStatus(result.status);
    }
  }, [projectid, props.status]);
*/

  // If the page is not yet generated, this will be displayed
  // initially until getStaticProps() finishes running

  /*if (router.isFallback) {
    return <div>Loading...</div>;
    return (
      <>
        <VideoHeader
          videoObj={status === "ready" ? videoObj : {}}
          meta={props.meta ? props.meta : {}}
          showHeader={props.showHeader}
        />
        <Skeleton width={width} height={adjustedHeight} />
        <div className="flex flex-col border shadow-md rounded-lg overflow-y-auto bg-white  ">
          <div className="flex flex-col  shadow-md rounded-lg overflow-y-auto bg-white  " style={textStyle}></div>
        </div>
      </>
    );
  } */
  if (status === "notAvailable") return <VideoNotAvailable />;
  if (status === "notReady") return <VideoIsBeingProcessed />;
  if (status === "ready" && videoObj)
    return <Video {...props} initialWidth={width} initialHeight={height} status={status} videoObj={videoObj} />;
  return null;
}
/*
export async function getStaticPaths() {
  // Call an external API endpoint to get posts
  const res = await fetch(`${config.socketIo}/api/getLatestPublicProjectsForBuild`);
  const posts = await res.json();

  // Get the paths we want to pre-render based on posts
  const paths = posts.map((post) => `/${post.projectid}`);

  // We'll pre-render only these paths at build time.
  // { fallback: false } means other routes should 404.
  return { paths, fallback: true };
} */

export async function getServerSideProps({ params, req }) {
  let { device } = UAParser(req.headers["user-agent"]);

  const deviceType = device?.type || null;

  if (params.video === "[video]") throw new Error("projectid === [video], likely a docker issue, restart containers");
  // params contains the post `id`.
  // If the route is like /posts/1, then params.id is 1

  const getResponse = async () => {
    const response = await fetch(`${config.socketIo}/player/loadProject`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ projectid: params.video }),
    });

    const json = await response.json();

    return json;
  };

  /*
  const getThumbnail = async () => {
    const response = await fetch(
      config.mediacache + "/api/cache-getVideoThumbnail?mode=url&width=200&height=111&projectid=" + params.video,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({ projectid: params.video }),
      }
    );

    const json = await response.json();

    return json;
  };

  const thumbnailPromise = await pRetry(getThumbnail, { retries: 3, factor: 1.5, minTimeout: 1000 });*/
  const result = await pRetry(getResponse, { retries: 3, factor: 1.5, minTimeout: 1000 });

  if (result.redirect) return { redirect: { destination: result.redirect } };

  const videoObj = result?.status === "ready" ? result?.video : null;
  const status = result?.status;
  const { showHeader, showNotes, posterImage, scenes, meta, elements, properties } = transformVideoMetadata(result);

  return {
    props: {
      projectid: params.video,
      videoObj,
      posterImage,
      scenes,
      showHeader,
      showNotes,
      meta,
      elements,
      properties,
      status,
      deviceType,
    },
    /*revalidate: 60, */
  };
}

export default VideoPage;
